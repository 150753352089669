<template>
  <div class="wrapper">
    <section class="carousel-section">
      <div
        id="carouselExampleIndicators"
        class="carousel slide"
        data-ride="carousel"
        data-interval="3000"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <router-link to="/join">
              <img
                class="d-block"
                src="/img/carousel/carousel.webp"
                style="height: 81px"
              />
            </router-link>
          </div>
          <div class="carousel-item">
            <router-link to="/deposit">
              <img
                class="d-block"
                src="/img/carousel/carousel.webp"
                style="height: 81px"
              />
            </router-link>
          </div>
          <div class="carousel-item">
            <router-link
              :to="{
                name: aviatorCrashGameUrl,
                params: {
                  IsDemo: 0,
                  providerID: aviatorProviderID,
                  gameName: aviatorGameName,
                  gameID: aviatorGameID,
                },
              }"
            >
              <img
                class="d-block"
                src="/img/carousel/carousel.webp"
                style="height: 81px"
              />
            </router-link>
          </div>
          <div class="carousel-item">
            <router-link
              :to="{
                name: jetxCrashGameUrl,
                params: {
                  IsDemo: 0,
                  providerID: jetxProviderID,
                  gameName: jetxGameName,
                  gameID: jetxGameID,
                },
              }"
            >
              <img
                class="d-block"
                src="/img/carousel/carousel.webp"
                style="height: 81px"
              />
            </router-link>
          </div>
        </div>
        <a
          class="carousel-control-prev"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="carousel-control-next"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: 'Slider',
  data: function () {
    return {
      aviatorProviderID: 1,
      aviatorGameID: 'aviator',
      aviatorGameName: 'Aviator',
      aviatorCrashGameUrl: 'aviator',
      //
      jetxProviderID: 5,
      jetxGameID: 'JetX',
      jetxGameName: 'Jet X',
      jetxCrashGameUrl: 'jetx',
    };
  },
  methods: {
    setSportMenu: function (sport_id, sport_name) {
      this.sport_name = sport_name;
      this.$store.dispatch('setSportID', sport_id);
      this.$store.dispatch('setCurrentPage', sport_name);
      console.log(this.$store.state.sport_id);
      this.$router.push({ name: 'sport', params: {} });
    },
  },
};
</script>
